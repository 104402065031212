<template>
  <a-modal
    :title="title"
    width="70%"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="
      () => {
        this.visible = false
      }
    "
  >
    <a-spin :spinning="loading"></a-spin>
    <vxe-button @click="handleAdd(1)" class="mb-2">添加</vxe-button>
    <vxe-button @click="handleAdd(5)" class="mb-2">添加5行</vxe-button>
    <vxe-button @click="handleAdd(10)" class="mb-2">添加10行</vxe-button>
    <vxe-button @click="handleAdd(20)" class="mb-2">添加20行</vxe-button>
    <vxe-table border resizable :data="tableData" :edit-config="{ trigger: 'click', mode: 'cell' }">
      <vxe-table-column type="seq" width="60"></vxe-table-column>
      <vxe-table-column
        field="Subject"
        title="检测项目"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column
        field="Require"
        title="技术要求"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column
        field="Method"
        title="检测方法"
        :edit-render="{ name: 'input', immediate: true, attrs: { type: 'text' } }"
      ></vxe-table-column>
      <vxe-table-column title="操作" width="140">
        <template v-slot="{ rowIndex }">
          <vxe-button @click="handleDelete(rowIndex)" status="danger">删除</vxe-button>
          <vxe-button @click="handlesplice(rowIndex)">插入</vxe-button>
        </template>
      </vxe-table-column>
    </vxe-table>
  </a-modal>
</template>

<script>
export default {
  name: 'EditInspect',
  props: {
    parentObj: Object,
  },
  data() {
    return {
      visible: false,
      loading: false,
      title: '工艺设定',
      tableData: [
        {
          Id: '',
          Subject: '',
          Require: '',
          Method: '',
        },
      ],
      id: null,
    }
  },
  methods: {
    openForm(id, _title) {
      this.visible = true
      this.id = id
      if (id) {
        this.loading = true
        this.$ajax(`/Main/Process/GetInspectionRules?processId=${id}`).then(resJson => {
          this.loading = false

          this.tableData = resJson.length
            ? resJson
            : [
                {
                  Id: '',
                  Subject: '',
                  Require: '',
                  Method: '',
                },
              ]
        })
      }
    },
    handleSubmit() {
      this.loading = true
      this.$http
        .post('/Main/Process/SetInspectionRules', {
          ProcessId: this.id,
          InspectionRuleDtos: this.tableData,
        })
        .then(resJson => {
          this.loading = false

          if (resJson.Success) {
            this.$message.success('操作成功!')
            this.visible = false

            this.parentObj.getDataList()
          } else {
            this.$message.error(resJson.Msg)
          }
        })
    },
    handleDelete(rowIndex) {
      this.tableData.splice(rowIndex, 1)
    },
    onCellChange() {},
    handleAdd(num) {
      for (var i = 0; i < num; i++) {
        this.tableData.push({
          Id: '',
          Subject: '',
          Require: '',
          Method: '',
        })
      }
    },
    handlesplice(index) {
      this.tableData.splice(index + 1, 0, {
        Id: '',
        Subject: '',
        Require: '',
        Method: '',
      })
    },
  },
}
</script>
